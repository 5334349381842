import React from "react";

export const BlurBottom = () => {
    return (
        <div className="blur-bottom">
        </div>
    );
};

export const BlurTop = () => {
    return (
        <div className="blur-top">
        </div>
    );
};
